import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useRollbar } from '@rollbar/react'
import { MergedVariantMetadata } from '../../clients/fagl-server/types/printify'
import config from '../../config'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import {
  CartItem,
  Provider,
} from '../../clients/fagl-server/types/photoProductsCart'
import { useApi } from '../../hooks/useApi'
import { chunk } from 'lodash'

const logs: { [key: string]: string[] } = {}

async function getBase64(url: string): Promise<string> {
  try {
    const response = await fetch(url, { method: 'GET' })
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const blob = await response.blob()

    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        resolve(reader.result as string)
      }
      reader.onerror = () => {
        reject(new Error('Failed to read the Blob as Base64'))
      }
      reader.readAsDataURL(blob)
    })
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to fetch and convert to Base64: ${error.message}`)
    } else {
      throw new Error('Failed to fetch and convert to Base64: Unknown error')
    }
  }
}

const timestamp = () => {
  return new Date().toISOString()
}

const addLog = (url: string, message: string) => {
  if (!config.isPrintifyPreviewLogsActive) {
    return
  }
  if (!logs[url]) {
    logs[url] = []
  }
  logs[url].push(`${timestamp()}: ${message}`)
}

async function fetchTimesUntilImageChanged(
  url: string,
  times: number,
  delay = 1000,
  initialBase64: string | null = null
) {
  let initialValue: string | null = initialBase64

  let i = 0
  addLog(url, 'Starting to fetch image')

  while (i <= times) {
    try {
      addLog(url, `Fetching image. Iteration: ${i}`)
      const value = await getBase64(url)
      if (!initialValue) {
        addLog(url, `Set URL initial value`)

        initialValue = value
      } else {
        const hasImageChanged = value !== initialValue
        addLog(url, `image has changed`)

        if (hasImageChanged) {
          return {
            isDoneTrying: true,
            isPreviewReady: true,
            times: i,
            base64: value,
            possiblySucceededOnFirstLoad: false,
          }
        } else if (i === times) {
          addLog(url, `reached max iterations: ${times}`)
          return {
            isDoneTrying: true,
            isPreviewReady: false,
            times: i,
            base64: value,
            possiblySucceededOnFirstLoad: true,
          }
        }
      }
    } catch (err) {
      console.error(err)
      addLog(url, `Error fetching image: ${err}`)
    }
    await new Promise((resolve) => setTimeout(resolve, delay))
    i++
  }

  addLog(
    url,
    `reached max iterations but skipped the max iterations check: ${times}`
  )
  return {
    isDoneTrying: true,
    isPreviewReady: false,
    times: i,
    possiblySucceededOnFirstLoad: true,
  }
}

export type PreviewUrlState = {
  isPreviewReady: boolean
  isDoneTrying: boolean
  isDefault: boolean
  url: string
  base64: string
  possiblySucceededOnFirstLoad: boolean
}

export default function usePrintify({
  blueprintId,
  defaultVariantId,
  editedCartItem,
}: {
  blueprintId: number
  defaultVariantId: number
  editedCartItem: CartItem | null
}) {
  const rollbar = useRollbar()
  const { api } = useApi()
  const {
    photoProducts: {
      printify: { recordPreviewSuccess, recordPreviewFailure },
    },
  } = useRecordUserAction(api)

  const [variantId, setVariantId] = useState<number>(
    editedCartItem?.metadata.variantId || defaultVariantId
  )

  const [showNotAllLoadedError, setShowNotAllLoadedError] = useState(true)
  const [isGettingPreview, setIsGettingPreview] = useState(false)
  const [isPreparingEdit, setIsPreparingEdit] = useState(false)
  const [isGettingPreviewComplete, setIsGettingPreviewComplete] =
    useState(false)

  const [sourceImages, setSourceImages] = useState<
    { usedUrl: string; originalUrl: string }[]
  >(editedCartItem?.metadata.usedImages ?? [])

  const [usedImageBase64, setUsedImageBase64] = useState<string | null>(null)

  const [itemIdentifier, setItemIdentifier] = useState<string | null>(
    editedCartItem?.itemIdentifier ?? null
  )

  const [quantity, setQuantity] = useState(editedCartItem?.quantity ?? 1)

  const [previewUrlState, setPreviewUrlState] = useState<
    Record<string, PreviewUrlState>
  >({})

  const [fetchLogs, setFetchLogs] = useState<{ [key: string]: string[] }>({})

  const resetState = useCallback(() => {
    setPreviewUrlState({})
    setFetchLogs({})
    setIsGettingPreviewComplete(false)
  }, [])

  const previewPhotosList = Object.values(previewUrlState)

  const uploadUsedImages = useCallback(
    async (
      imageUrls: {
        original: string
        cropped: string
      }[]
    ) => {
      try {
        const result = await Promise.all(
          imageUrls.map(async (image) => {
            const [cropped, original] = await Promise.all([
              api.uploadBase64Image({
                base64: image.cropped,
                name: 'cropped',
              }),
              api.uploadBase64Image({
                base64: image.original,
                name: 'original',
              }),
            ])
            return {
              usedUrl: cropped.url,
              originalUrl: original.url,
            }
          })
        )

        setSourceImages(result)
        return result
      } catch (err) {
        rollbar.error('Error uploading used images', err as Error)
        console.error(err)
        return []
      }
    },
    []
  )

  const generatePreview = useCallback(
    async ({
      variant,
      newUsedImageBase64,
      usedImageDataUrls,
    }: {
      variant: MergedVariantMetadata
      newUsedImageBase64: string
      usedImageDataUrls: {
        original: string
        cropped: string
      }[]
    }) => {
      // Convert the source photo data URLs to a CDN URLs and save to state.
      // When adding a cart item, we need those URLs to be stored in the cart item metadata
      // so we can restore them when editing the item
      uploadUsedImages(usedImageDataUrls)

      setVariantId(variant.id)
      // If we already have a base order ID, we should not create a new one
      if (newUsedImageBase64 === usedImageBase64 && variant.id === variantId) {
        return
      }

      setIsGettingPreview(true)
      setIsGettingPreviewComplete(false)
      setPreviewUrlState({})
      setUsedImageBase64(newUsedImageBase64)

      let imageUrl = ''

      try {
        const { url } = await api.uploadBase64Image({
          base64: newUsedImageBase64,
          name: 'final',
        })
        imageUrl = url
      } catch (err) {
        toast.error('Something went wrong. Please try again later.')
        rollbar.error('Error uploading base 64 image', err as Error)
        console.error(err)
      }

      if (!imageUrl) {
        return
      }

      try {
        const { images, productId } = await api.printify.getImagesForPreview({
          blueprintId,
          variantId,
          imageUrl,
          providerId: variant.providerId,
        })

        setItemIdentifier(productId)

        await getPreview(
          images.map((image) => ({ ...image, initialBase64: '' }))
        )
      } catch (err) {
        toast.error('Something went wrong. Please try again later.')
        rollbar.error(
          'Error creating Printify order for preview purposes',
          err as Error
        )
        console.error(err)
      } finally {
        setIsGettingPreview(false)
      }
    },
    [blueprintId, usedImageBase64, api, rollbar, quantity]
  )

  const getPreview = useCallback(
    async (
      images: {
        src: string
        is_default: boolean
        initialBase64: string
      }[]
    ) => {
      setIsGettingPreview(true)
      setShowNotAllLoadedError(false)

      const initialStateForProcessedImages = Object.fromEntries(
        images.map((image) => [
          image.src,
          {
            isDoneTrying: false,
            isPreviewReady: false,
            isDefault: image.is_default,
            url: image.src,
            base64: image.initialBase64,
            possiblySucceededOnFirstLoad: false,
          },
        ])
      )

      // Since we are fetching potentially only a subset of the images, we
      // need to merge their fresh state with the existing state.
      setPreviewUrlState((state) => ({
        ...state,
        ...initialStateForProcessedImages,
      }))

      const handleUrl = async (url: string, initialBase64: string) => {
        const {
          isDoneTrying,
          isPreviewReady,
          times,
          base64 = '',
          possiblySucceededOnFirstLoad,
        } = await fetchTimesUntilImageChanged(
          url,
          10,
          1500,
          initialBase64 || null
        )
        addLog(
          url,
          `Fetch complete. isDoneTrying: ${isDoneTrying}, isPreviewReady: ${isPreviewReady}, times: ${times}, isDefault: ${previewUrlState[url]?.isDefault}`
        )

        setPreviewUrlState((prev) => ({
          ...prev,
          [url]: {
            isDoneTrying,
            isPreviewReady,
            isDefault: prev[url].isDefault,
            url,
            base64,
            possiblySucceededOnFirstLoad,
          },
        }))
      }

      const promises = images.map((src) =>
        handleUrl(src.src, src.initialBase64)
      )
      await Promise.all(promises)
      setFetchLogs(logs)
      setIsGettingPreview(false)
    },
    [
      setPreviewUrlState,
      setIsGettingPreview,
      setShowNotAllLoadedError,
      previewUrlState,
      rollbar,
    ]
  )

  const regeneratePreview = useCallback(async () => {
    setShowNotAllLoadedError(false)

    // const previewPhotosWhichAreNotReady = previewPhotosList.filter(
    //   (preview) => !preview.isPreviewReady
    // )

    // const payloadForGetPreviewForNotReadyPhotos =
    //   previewPhotosWhichAreNotReady.map((preview) => ({
    //     src: preview.url,
    //     is_default: preview.isDefault,
    //     initialBase64: preview.base64,
    //   }))

    const payloadForGetPreviewForNotReadyPhotos = previewPhotosList.map(
      (preview) => ({
        src: preview.url,
        is_default: preview.isDefault,
        initialBase64: '',
      })
    )

    getPreview(payloadForGetPreviewForNotReadyPhotos)
  }, [previewPhotosList])

  async function convertCdnPhotosToBase64(
    usedImages: {
      originalUrl: string
      usedUrl: string
    }[]
  ) {
    setIsPreparingEdit(true)
    const response = await api.convertCdnPhotosToBase64(
      usedImages.flatMap((image) => [
        { url: image.originalUrl, uuid: new Date().getTime().toString() },
        { url: image.usedUrl, uuid: new Date().getTime().toString() },
      ])
    )

    const convertedImages = chunk(response, 2).map(([original, used]) => ({
      originalUrl: original.url,
      usedUrl: used.url,
    }))

    setSourceImages(convertedImages)
    setIsPreparingEdit(false)
  }

  const updateQuantityAndVariant = useCallback(
    (quantity: number, variantId: number) => {
      setQuantity(quantity)
      setVariantId(variantId)
    },
    [setQuantity, setVariantId]
  )

  const previewStatus = useMemo(() => {
    if (previewPhotosList.length === 0) {
      return { logPayload: { numberOfPhotos: 0 }, status: 'PENDING' }
    }

    const readyPreviews = previewPhotosList.filter(
      (preview) => preview.isPreviewReady && preview.base64
    )

    const areAllPreviewsReady =
      readyPreviews.length === previewPhotosList.length

    const areAllDoneTrying = previewPhotosList.every(
      (preview) => preview.isDoneTrying
    )

    if (!areAllDoneTrying) {
      return {
        status: 'PENDING',
        logPayload: {
          numberOfPhotos: previewPhotosList.length,
          numberOfPhotosReady: readyPreviews.length,
        },
      }
    }

    const areZeroPreviewsReady = readyPreviews.length === 0
    const possiblySucceededOnFirstLoad = previewPhotosList.some(
      (preview) => preview.possiblySucceededOnFirstLoad
    )

    const logPayload = {
      numberOfPhotos: previewPhotosList.length,
      numberOfPhotosReady: readyPreviews.length,
      possiblySucceededOnFirstLoad,
    }
    if (
      areAllPreviewsReady ||
      (areZeroPreviewsReady && possiblySucceededOnFirstLoad)
    ) {
      return { status: 'SUCCESS', logPayload }
    } else {
      return { status: 'FAILURE', logPayload }
    }
  }, [previewPhotosList])

  useEffect(() => {
    const { status, logPayload } = previewStatus
    if (status !== 'PENDING' && !isGettingPreviewComplete) {
      if (status === 'SUCCESS') {
        recordPreviewSuccess(logPayload)
      } else if (status === 'FAILURE') {
        setShowNotAllLoadedError(true)
        recordPreviewFailure(logPayload)
        rollbar.warning('Printify previews not ready', logPayload)
      }
      setIsGettingPreviewComplete(true)
    }
  }, [previewStatus, isGettingPreviewComplete])

  const previewCdnUrl =
    previewPhotosList.find((photo) => photo.isDefault)?.url ||
    previewPhotosList[0]?.url

  const createPayloadWithVariant = useCallback(
    (selectedVariant: MergedVariantMetadata) => {
      return {
        itemIdentifier,
        provider: Provider.PRINTIFY,
        metadata: {
          blueprintId,
          previewCdnUrl,
          usedImages: sourceImages,
          printProviderId: selectedVariant.providerId,
          variantId: selectedVariant.id,
          price: selectedVariant.price,
          title: selectedVariant.title,
        },
      }
    },
    [blueprintId, previewCdnUrl, sourceImages, itemIdentifier]
  )

  // Convert the source images to base64 so we can use them in the personalize screen
  useEffect(() => {
    if (editedCartItem) {
      convertCdnPhotosToBase64(editedCartItem.metadata.usedImages)
    }
  }, [editedCartItem])

  return {
    quantity,
    setQuantity,
    blueprintId,
    generatePreview,
    previewPhotosList,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
    updateQuantityAndVariant,
    createPayloadWithVariant,
    variantId,
    resetState,
    sourceImages,
    isPreparingEdit,
  }
}
